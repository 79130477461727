import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './App.less'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { Staking } from './pages/Staking/index'
import { UnderConstruction } from './pages/UnderConstruction/index'
import { Home } from './pages/Home/index'
import http from './services/http';

import Loading from './components/Loading/Loading'
import Armory from './pages/Armory'
import WhiteList from './pages/WhiteList';
import MintWeapons from './pages/MintWeapons';
import NFTpage from './pages/NFT';
import MyWeapons from './pages/MyWeapons';
import MintReboot from './pages/MintReboot';
import TokenAddress from './pages/TokenAddress';
import PlayFrame from './pages/PlayFrame';
import Privacy from './pages/Privacy';
import NotFound from './pages/NotFound';

import AccountModal from './components/AccountModal';
import UndeadToast from './components/UndeadToast';
import TournamentPopup from './components/TournamentPopup';
import DailyRewardsPopup from './components/DailyRewardsPopup';
import { ConnectToWalletModal } from './containers/ConnectToWalletModal';
import WrongNetworkModal from './components/WrongNetworkModal';

import { toggleWalletModal, checkWrongNetwork } from './actions/common';
import { fetchAllSettings } from './actions/setting';
import { PAGE_NFT } from './utils/constants';

function App() {
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);

  const isWalletModalVisible = useSelector(state => state.common.isWalletModalVisible);
  const { isConnect } = useSelector(state => state.user?.connectWallet ?? false);
  const account = useSelector(state => state.user?.userAccount?.accounts[0] ?? '');
  const chainId = useSelector(state => state.user?.chainId ?? 5);
  const token = useSelector(state => state.user?.userAccount?.token);

  window.addEventListener('load', () => {
    setLoaded(true)
  })

  setTimeout(() => {
    setLoaded(true)
  }, 6000)

  if (token) {
    http.setAuthorizationHeader(token);
  }

  useEffect(() => {
    (!isConnect || !account || !chainId) && dispatch(checkWrongNetwork(false));
  }, [dispatch, isConnect, account, chainId]);

  useEffect(() => {
    dispatch(fetchAllSettings());
  }, [dispatch]);

  return (
    <>
      <Loading loaded={loaded} />
      <div className="App">
        <Router basename="/">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/under-construction" component={UnderConstruction} />
            <Route path="/staking" component={UnderConstruction} />
            <Route path="/armory" component={Armory} />
            <Route path="/whitelist" component={WhiteList} />
            <Route path="/mint-weapons" component={UnderConstruction} />
            <Route path="/mint-reboot" component={UnderConstruction} />
            <Route exact path={`${PAGE_NFT}/:tokenId`} component={UnderConstruction} />
            <Route exact path={`${PAGE_NFT}/:contractAddress/:tokenId`} component={UnderConstruction} />
            <Route path="/my-weapons" component={UnderConstruction} />
            <Route path="/token-address" component={TokenAddress} />
            <Route exact path="/play" component={PlayFrame} />
            <Route path="/privacy" component={Privacy} />
            <Route component={NotFound} key="*" />
          </Switch>
        </Router>

        {/* <ToastListener /> */}
        <ConnectToWalletModal visible={isWalletModalVisible} onCancel={() => dispatch(toggleWalletModal(false))} />
        <AccountModal />
        <WrongNetworkModal />
        <UndeadToast />
        <TournamentPopup />
        <DailyRewardsPopup />
      </div>
    </>
  )
}

export default App
