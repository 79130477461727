import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Header from '../Home/LandingPageHeader/Header'

import leftLayer from '../../assets/img/leftLayer.png';
import rightLayer from '../../assets/img/rightLayer.png';
import UndeadBlocks from '../../assets/img/undead_block_logo.png';
// import layer from '../../../assets/img/bloodBackground.png';

const Wrapper = styled.div`
  background: #000000;
  width: 100%;
  min-height: 100vh;
  padding: 126rem 0 0 0;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    padding: 350rem 0 0 0;
  }
`
const Info = styled.div`
  margin: 0rem 95rem 27rem;
  position: relative;
  
  @media only screen and (max-width: 767px) {
    margin: 0rem 88rem 98rem;
  }
`
const Title = styled.div`
  font-size: 32.5rem;
  font-weight: bold;
  line-height: 0.92;
  text-align: center;
  color: #fff;
  margin-top:20rem; 
  margin-bottom: 14.5rem;

  @media (max-width: 767px) {
    font-size: 74rem;
    margin-bottom: 35rem;
  }
`
const Description = styled.p`
  font-size: 15rem;
  line-height: 1.5;
  text-align: center;
  color: #fff;
  margin-top:50rem;
  @media (max-width: 767px) {
    font-size: 35rem;
  }
`
const BubbleLayer = styled.div`
    position: relative;
    pointer-events: none;

    @media screen and (max-width: 767px) {
      display: none;
    }

    .left {
        position: absolute;
        left: -20rem;
        top: -110rem;
        width: 384rem;
        height: 720rem;
        opacity: 0.6;
    }

    .right {
        position: absolute;
        top: 75rem;
        opacity: 0.6;
        right: -30rem;

        width: 535.5rem;
    }

    .center {
        margin: 40rem auto;
        width: 200rem;
        max-height: 220rem;
    }
`;


export function UnderConstruction({ chainId, account, isConnected, isMobile, isTablet, isShowPopup, onClosePopup }) {
  const dispatch = useDispatch();

  



  return (
    <Wrapper>
      

      <Header isStaking={true} />
  
      <Info>
        <Title>Under Construction</Title>
        <Description>
          Our servers are currently under maintenance, they'll be back and stronger than ever soon.
        </Description>
       
       
      </Info>

      

      
    </Wrapper>
  )
}
