import React from 'react'
import { Image } from 'antd'
import styled from 'styled-components'
import background from '../assets/img/bg_bottom_new.jpg'
import background_mobile from '../assets/img/bg_bottom_mobile_new.jpg'
import discord from '../assets/img/discord.png'
import iconTwelve from '../assets/img/iconTwelve.png'
import medium from '../assets/img/medium.png'
import twitter from '../assets/img/twitter.png'
import flan from '../assets/img/flan.png'
import logo from '../assets/img/logo.png'
import linktree from '../assets/img/linktree.png'



const ContentSeven = styled.div`
  background: ${props => props.isCustom ? 'black' : '#111114' };
  width: 100%;
  padding: 0 30px 0 30px;
  height: 100%;

  @media screen and (min-width: 769px) {
    padding: 0 100px 0 100px;
  }
  @media screen and (min-width: 1280px) {
    padding: 0 100px 0 100px;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 100px 0 100px;
  }
  @media screen and (min-width: 1920px) {
    padding: 0 100px 0 100px;
  }
`
const Box = styled.div`
  display: flex;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;

  @media screen and (max-width: 1440px) {
    padding-top: ${props => props.isCustom ? '73px' : '100px' };
  }
`
const TitleHead = styled.div`
  font-size: 29px;
  font-weight: bold;
  color: #ffffff;

  @media screen and (min-width: 769px) {
    font-size: 30px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 32px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 35px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 38px;
  }
  @media screen and (max-width: 480px) {
    font-size: 25px;
  }
`
const TitleMain = styled.div`
  font-size: 13px;
  color: #ffffff;
  padding-top: 10px;
  @media screen and (min-width: 769px) {
    font-size: 15px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 15px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 17px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 20px;
  }
  @media screen and (max-width: 480px) {
    padding-top: 0;
  }
`
const Rows = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`
const ColLeft = styled.div`
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10%;
  @media screen and (max-width: 1440px) {
    padding-top: 27px;
    padding-left: 7%;
  }
  @media screen and (max-width: 768px) {
    padding: 250px 0 0;
    flex: 0 0 100%;
    align-items: center;
    justify-content: start;
  }
  @media screen and (max-width: 480px) {
    padding: 150px 0 0;
  }
`
const Icon = styled.div`
  display: flex;
  width: 35%;
  justify-content: space-between;
  padding-top: 20px;
  @media screen and (max-width: 1440px) {
    padding-top: 30px;
    width: 38%;
  }
  @media screen and (max-width: 480px) {
    padding-top: 10px;
    width: 80%;
  }
`
const Img = styled(Image)`
  width: 70%;
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width: 1440px) {
    width: 55%;
  }
  @media screen and (max-width: 480px) {
    padding: 0;
    width: 32px;
  }
`
const Footer = styled(Image)`
  width: 80%;
  height: 80%;
  margin: 0 auto;
  :hover {
    cursor: pointer;
  }
`
const TextFooter = styled.div`
  font-size: 17px;
  color: #ffffff;
  padding-bottom: 10px;
  text-align: center;
`
const PrivacyText = styled.p`
  font-size: 17px;
  color: #d30007;
  margin-bottom: 50px;
  text-align: center;
  display: inline-flex;

  cursor: pointer;
`

const Background = styled.img`
  height: 100%;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const BackgroundMobile = styled.img`
  display: none;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: block;
  }
`
const currentYear = new Date().getFullYear();

const ComponentTwelve = (props) => {
  const { isCustom } = props;

  return (
    <ContentSeven isCustom={isCustom}>
      <Box isCustom={isCustom} preview={false} alt={''}>
        <Background src={background} alt="" />
        <BackgroundMobile src={background_mobile} alt="" />
        <Rows>
          <ColLeft>
            <TitleHead>It's Zombie Killing Time</TitleHead>
            <TitleMain>Learn more about Undead Blocks by following our communities.</TitleMain>
            <Icon>
              <a href="https://t.me/undeadblocks" target="_blank" rel="noreferrer">
                <Img src={flan} preview={false} />
              </a>
              <a href="https://twitter.com/UndeadBlocks" target="_blank" rel="noreferrer">
                <Img src={twitter} preview={false} />
              </a>
              <a href="https://linktr.ee/officialundeadblocks" target="_blank" rel="noreferrer">
                <Img src={linktree} preview={false} />
              </a>
              <a
                href="https://discord.com/invite/officialundeadblocks"
                target="_blank"
                rel="noreferrer">
                <Img src={discord} preview={false} />
              </a>
              <a href="https://medium.com/@UndeadBlocks" target="_blank" rel="noreferrer">
                <Img src={medium} preview={false} />
              </a>
              <a href='https://etherscan.io/address/0x310C8F00b9dE3c31Ab95ea68feb6C877538f7947'  target="_blank" rel="noreferrer">
                <Img src={iconTwelve} preview={false} />
              </a>
            </Icon>
          </ColLeft>
          {/* <ColRight>
            <GamePad src={img_gamepad} alt={''} />
          </ColRight> */}
        </Rows>
      </Box>
      <Footer src={logo} preview={false} />
      <TextFooter>Copyright {currentYear} by Wagyu Games Studio</TextFooter>
      <PrivacyText onClick={() => window.open('/privacy', '_blank')}>
        Terms & Service
      </PrivacyText>
    </ContentSeven>
  )
}

export default ComponentTwelve
