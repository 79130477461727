import React from 'react'
import { Button, Layout } from 'antd'
import styled from 'styled-components'

import linkedIn_bg from './../assets/img/keruvu_bg.png'
import LinkedIn from './../assets/img/linkedin_icon.png'

import LinkedInIcon from './../assets/linkedin.png';
import InstagramIcon from './../assets/instagram.png';
import WagyuLogo from './../assets/banner-header/logo-white.png'
import img_kyle from './../assets/kyle.webp'
import img_ken from './../assets/ken.webp'
import img_delroy from './../assets/delroy.webp'
import img_flu from './../assets/flu.webp'
import img_joe from './../assets/joe.webp'

const { Content } = Layout

const list = [
  {
    avatar: img_kyle,
    name: 'Kyle Gordon',
    position: 'CEO / Founder',
    instagram: '',
    linkedIn: ''
  },
  {
    avatar: img_ken,
    name: 'Ken Gamble',
    position: 'COO / Founder',
    instagram: '',
    linkedIn: ''
  },
  {
    avatar: img_delroy,
    name: 'Delroy Brown',
    position: 'Chief Technology Officer',
    instagram: '',
    linkedIn: ''
  },
  {
    avatar: img_flu,
    name: 'James (Flu) Griffin',
    position: 'Chief Business Director',
    instagram: '',
    linkedIn: ''
  },
  {
    avatar: img_joe,
    name: 'Joe Manns',
    position: 'Senior Software Designer',
    instagram: '',
    linkedIn: ''
  }
];

const ContentTitle = styled(Content)`
  padding: 0 20px;
`
const ContentOne = styled(Layout)`
  background: #111114;
  padding: 60px 40px 0;

  @media screen and (min-width: 1280px) {
    padding: 60px 0px 0;
  }
  @media screen and (min-width: 1920px) {
    padding: 0 110px 0;
  }
  @media screen and (min-width: 2560px) {
    padding: 0 110px 0;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`
const TitleOne = styled.div`
  color: white;
  font-size: 29px;
  text-align: center;
  font-weight: bold;
  @media screen and (min-width: 769px) {
    text-align: center;
    font-size: 30px;
  }
  @media screen and (min-width: 1280px) {
    text-align: center;
    font-size: 32.5px;
  }
  @media screen and (min-width: 1440px) {
    text-align: center;
    font-size: 35px;
  }
  @media screen and (min-width: 1920px) {
    text-align: center;
    font-size: 40px;
  }
`
const TitleTwo = styled.div`
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  padding: 5px 0 60px;

  @media screen and (min-width: 769px) {
    font-size: 15px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 15px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 20px;
  }
`
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 50px 20px;

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;

  width: calc(20% - 50px);

  .social {
    margin-top: 10px;
    display: flex;
    justify-content: center;

    img {
      width: 24px;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    width: calc(50% - 50px);
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: calc(100% / 3 - 50px);
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    width: calc(100% / 4 - 50px);
  }

  @media (min-width: 1280px) and (max-width: 1570px) {
    // width: calc(100% / 4 - 50px);
  }
`
const TextName = styled.div`
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  margin-top: 15px;

  @media screen and (min-width: 769px) {
    text-align: center;
    font-size: 20px;
  }
  @media screen and (min-width: 1280px) {
    text-align: center;
    font-size: 23px;
    font-weight: 700;
    margin-top: 20px;
  }
  @media screen and (min-width: 1440px) {
    text-align: center;
    font-size: 23px;
  }
  @media screen and (min-width: 1920px) {
    text-align: center;
    font-size: 28px;
  }
  @media screen and (max-width: 480px) {
    text-align: center;
    font-size: 16px;
  }
`

const TextPosition = styled.p`
  color: #bfbfbf;
  font-size: 17px;
  margin-bottom: 0;
  white-space: pre-line;
  line-height: 1.2;

  @media screen and (min-width: 769px) {
    text-align: center;
    font-size: 15px;
  }
  @media screen and (min-width: 1280px) {
    text-align: center;
    font-size: 18px;
  }
  @media screen and (min-width: 1440px) {
    text-align: center;
    font-size: 18px;
  }
  @media screen and (min-width: 1920px) {
    text-align: center;
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    text-align: center;
    font-size: 15px;
  }
`
const Logo = styled.div`
  img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: solid 5px #df1e33;
    box-shadow: 0 0 25px 0 rgba(223, 30, 51, 0.5);
    object-fit: cover;
  }

  @media (max-width: 767px) {
    img {
      width: 100px;
      height: 100px;
    }
  }
`

const ButtonTeam = styled(Button)`
  text-align: center;
  align-items: center;
  padding: 0;
  background: url(${linkedIn_bg});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 50px;
  border: unset;
  width: 250px;
  justify-content: center;
  margin-top: 50px;
  line-height: 50px !important;
  img {
    margin-right: 10px;
    width: 35px;
    height: auto;
  }
  span {
    color: #fff;
    font-size: 15.3px;
    font-weight: 600;
  }
  :hover,
  :active,
  :focus {
    background: url(${linkedIn_bg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  @media screen and (max-width: 1280px) {
    font-size: 15.3px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`
const ComponentSix = () => {
  return (
    <ContentOne>
      <ContentTitle>
        <TitleOne>We're Bringing Console Quality to Crypto.</TitleOne>
        <TitleTwo>Undead Blocks is being developed & designed by Wagyu Games, LLC.</TitleTwo>
      </ContentTitle>

      <Content>
        <Container>
          {list && list.map((item, index) => {
            const socialIcon = item.instagram || item.linkedIn
              ? (item.instagram ? InstagramIcon : LinkedInIcon)
              : null

            return (
              <Item key={index}>
                <section style={{ flexShrink: 0 }}>
                  <Logo>
                    <img src={item.avatar} alt={''} />
                  </Logo>
                </section>

                <div className={'content'}>
                  <section>
                    <TextName>{item.name}</TextName>
                    <TextPosition>{item.position}</TextPosition>
                  </section>

                  <section className="social">
                    {item.instagram || item.linkedIn ? (
                      // index === 1 ? <img src={socialIcon} alt="social" /> : (
                      //   <a href={item.instagram || item.linkedIn} target={'_blank'} rel={'noreferrer'}>
                      //     <img src={socialIcon} alt="social" />
                      //   </a>
                      // )
                      <a href={item.instagram || item.linkedIn} target={'_blank'} rel={'noreferrer'}>
                        <img src={socialIcon} alt="social" />
                      </a>
                    ) : null}
                  </section>
                </div>

                {/* <section style={{ flex: 2 }}>
                  <TextName>{item.name}</TextName>
                  <TextPosition>{item.position}</TextPosition>
                </section>

                <section className="social" style={{ flex: 1 }}>
                  {item.instagram || item.linkedIn ? (
                    <a href={item.instagram || item.linkedIn} target={'_blank'} rel={'noreferrer'}>
                      <img src={socialIcon} alt="social" />
                    </a>
                  ) : null}
                </section> */}
              </Item>
            )
          })}
        </Container>

        <ButtonTeam
          icon={<img src={WagyuLogo} alt="" />}
          size={'large'}
          href="https://wagyugames.com/our-team"
          target="_blank">
          Meet The Team
        </ButtonTeam>
      </Content>
    </ContentOne>
  )
}

export default ComponentSix
