import React from 'react';
// import { useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import styled from 'styled-components'

const Nav = styled(Menu)`
  position: absolute;
  top: calc(50% + 10rem);
  left: 50%;
  transform: translate(-50%, calc(-50%));
  display: flex;
  background-color: unset;

  @media screen and (max-width: 767px) {
    position: static;
    transform: unset;
    top: unset;
    left: unset;
    display: flex;
    flex-direction: column;

    padding: 0 20rem;

    margin-bottom: 70rem;
  }
`
const NavItem = styled(Menu.Item)`
  padding: 0 12rem;

  :hover,
  :active,
  :focus {
    background-color: unset;
  }

  @media screen and (max-width: 767px) {
    padding: 0 0;
  }

  span {
    a {
      margin: 0;
      font-family: Poppins;
      font-size: 13.5rem;
      font-weight: 500;
      line-height: 1;
      text-align: center;
      color: #fff;

      :hover,
      :active,
      :focus {
        color: #df1e33;
      }

      @media screen and (max-width: 767px) {
        font-size: 45rem;
      }
    }
  }
`
export default function LeftMenu() {
  // const location = useLocation();

  // const handleScrollClick = (event, id) => {
  //   if (location.pathname === '/') {
  //     const element = document.getElementById(id)
  //     element.scrollIntoView({ behavior: 'smooth' })
  //     event.preventDefault()
  //   }
  // }

  return (
    <Nav>
      {/* <NavItem key="home">
        <a href="/">Home</a>
      </NavItem> */}

      {/* <NavItem key="features">
        <a href="/#features" onClick={e => handleScrollClick(e, 'features')}>
          Features
        </a>
      </NavItem> */}

      <NavItem key="token-address">
        <a href="/token-address">UNDEAD Token</a>
      </NavItem>

      <NavItem key="staking">
        <a href="/staking">Staking Pools</a>
      </NavItem>

      {/* <NavItem key="about-us">
        <a href="/armory">Armory</a>
      </NavItem> */}

      {/* <NavItem key="white-list">
        <a href="/whitelist">Whitelist</a>
      </NavItem> */}

      {/* <NavItem key="mint-weapons">
        <a href="/mint-weapons">Mint Weapons</a>
      </NavItem> */}

      <NavItem key="my-weapons">
        <a href="/my-weapons">My Weapons</a>
      </NavItem>

      <NavItem key="wagyu-games">
        <a href="https://wagyu-games.gitbook.io/undead-blocks-whitepaper" target="_blank" rel="noreferrer">WhitePaper</a>
      </NavItem>

      <NavItem key="survivor-shop">
        <a href="https://wagyugames.com" target="_blank" rel="noreferrer">Wagyu Games</a>
      </NavItem>
    </Nav>
  )
}
